import { Dexie } from 'dexie';
import { cacheReset } from '@/utils/helpers/cacheReset';
import { ErrorTriggerEvent } from '@/features/sync-scheduler';
import { VerificationDialogShow } from '@/features/login/events';
import { $t } from '@/i18n';
import { EventBus } from '../event-bus';
import { NotificationService, NotificationType } from '../notifications';
import {
  BaseError,
  HandledError,
  isErrorWithMessage,
  UnknownError,
} from './error-classes';
import { LoggerService } from '@/features/core/logger';

export interface ErrorHandler {
  handle(error: unknown): void;
  setVisibleErrorPopup(visibility: boolean): void;
  registerLoggerService(loggerService: LoggerService): void;
}

export class ErrorHandlerImpl implements ErrorHandler {
  private showExpireTokenPopup = false;
  public visibleErrorPopup = false;
  private loggerService?: LoggerService;
  constructor(
    private notificationService: NotificationService,
    private eventBus: EventBus,
    private windowInstance = globalThis,
  ) {
    this.windowInstance?.addEventListener(
      'unhandledrejection',
      (event: PromiseRejectionEvent) => {
        const error: Error | unknown = event.reason;
        if (
          error instanceof Dexie.DexieError &&
          confirm($t('errors.error-handler.dexie-error.message'))
        ) {
          this.loggerService?.error('DexieError is thrown', error);
          void cacheReset();
        }
      },
    );

    this.registerErrorListener();

    this.eventBus.on(VerificationDialogShow, () => {
      this.showExpireTokenPopup = true;
    });
  }

  setVisibleErrorPopup(visible: boolean): void {
    this.visibleErrorPopup = visible;
  }

  handle(error: BaseError | HandledError | unknown): void {
    if (!error || error instanceof HandledError) return;
    let errorObj;
    if (error instanceof BaseError) {
      if (error.handled === true) return;
      errorObj = error;
    } else {
      const errorReasonMessage = isErrorWithMessage(error)
        ? error.message
        : JSON.stringify(error);
      errorObj = new UnknownError({ ErrorReason: errorReasonMessage });
    }
    if (this.showExpireTokenPopup || this.visibleErrorPopup) return;
    this.notifyAboutError(errorObj);
  }

  private notifyAboutError(error: BaseError): void {
    this.eventBus.emit(new ErrorTriggerEvent(error.message));
    this.loggerService?.log(error.getLogLevel(), error);
    if (error.originalError && error.originalError instanceof Error) {
      this.loggerService?.log(error.getLogLevel(), error.originalError);
    }
  }

  public registerLoggerService(loggerService: LoggerService): void {
    this.loggerService = loggerService;
  }

  private registerErrorListener() {
    this.eventBus.on(ErrorTriggerEvent, (event) => {
      this.notificationService.show({
        text: event.error,
        type: NotificationType.Error,
      });
    });
  }
}
